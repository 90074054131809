import * as React from "react";
import styled from "styled-components";
import { AppTitle, AppText, FlexDiv } from "../../ui";
import { mobileSize, laptopSize } from "../../../util/variables";
import { AppLink } from "../../ui/AppLink";
import theme from "../../../util/theme";
import { graphql, useStaticQuery } from "gatsby";
import { OfferSegmentI } from "../../../hooks/useOffer";
import { calculateDiscountedPrice } from "../../../util/numbers";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";

export const HeroSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { trial, percentOff, annual_price, currency_symbol, price_id, coupon_id } = priceData;
  const dataHero = useStaticQuery(graphql`
    query OfferHeroImageQuery {
      heroMobile: file(relativePath: { eq: "offer/hero-mobile.webp" }) {
        childImageSharp {
          fixed(quality: 100, width: 400, height: 400) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      heroDesktop: file(relativePath: { eq: "offer/hero-desktop.webp" }) {
        childImageSharp {
          fixed(quality: 100, width: 508, height: 770) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  const getOfferText = () => {
    const trialString = "7-Day Free Trial";
    const percentString = `${percentOff}% OFF`;
    if (trial && percentOff) {
      return `${trialString} + ${percentString}`;
    }

    return trial ? trialString : percentString;
  };

  const discountedPrice = calculateDiscountedPrice(Number(annual_price), percentOff ?? 0);

  return (
    <FlexDiv justify="center" style={{ backgroundColor: theme.PRIMARY_COLOR }}>
      <HeroContainer>
        <HeroContent>
          <AppText fontSize={40} mobileFontSize={32} fontWeight={700} style={{ marginBottom: 16 }}>
            {getOfferText()}
          </AppText>
          <LargeHeading>The world’s best knowledge, visualized.</LargeHeading>
          <Card>
            <AppText noMargin fontSize={32} mobileFontSize={20} fontWeight={500}>
              Imprint Premium Offer
            </AppText>
            <FlexDiv gap={24}>
              {coupon_id && (
                <AppText
                  noMargin
                  fontSize={52}
                  mobileFontSize={36}
                  fontWeight={300}
                  color={theme.SLATE_COLOR_2}
                >
                  <s>
                    {currency_symbol}
                    {annual_price}
                  </s>
                </AppText>
              )}
              <AppText
                noMargin
                fontSize={52}
                mobileFontSize={36}
                fontWeight={500}
                color={theme.PRIMARY_COLOR}
              >
                {currency_symbol}
                {discountedPrice}
              </AppText>
            </FlexDiv>
            <AppText noMargin fontSize={20}>
              {percentOff ? `Get ${percentOff}% off your first year` : "Start Imprint Free Trial"}
            </AppText>
          </Card>
          <AppLink
            dark
            to="/subscription/register?from=email"
            state={{
              couponId: coupon_id,
              priceId: price_id,
              trial,
            }}
            style={{ marginBottom: 24 }}
            onClick={() => offer_landing_page_click("hero")}
          >
            Claim Offer
          </AppLink>
          <CenterWrapper>
            <AppText>
              Discount on in-app price. Recurring annual billing. Additional taxes and fees may
              apply. Cancel Anytime.
            </AppText>
          </CenterWrapper>
        </HeroContent>
        <HeroImgDiv>
          <picture>
            {/* media doesn't work with template literals */}
            <source
              srcSet={dataHero.heroMobile.childImageSharp.fixed.src}
              media="(max-width: 768px)"
            />
            <source
              srcSet={dataHero.heroDesktop.childImageSharp.fixed.src}
              media="(min-width: 768px)"
            />
            <img src={dataHero.heroDesktop.childImageSharp.fixed.src} alt="Hero Image" />
          </picture>
        </HeroImgDiv>
      </HeroContainer>
    </FlexDiv>
  );
};

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  width: 100vw;
  max-width: 1920px;
  padding-left: 64px;

  @media ${mobileSize} {
    flex-direction: column;
    padding: 0;
  }
`;

const HeroContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  padding: 64px 0;
  padding-left: 5vw;
  /* gap: 40px; */
  color: ${theme.WHITE_COLOR};
  width: 60%;
  z-index: 2;

  @media ${mobileSize} {
    width: 100%;
    padding: 45px 24px 0 24px;
  }
`;

const HeroImgDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 550px;
  width: 50%;
  height: 100%;
  img {
    object-fit: cover;
    object-position: right;
    min-height: 100%;
    /* max-height: 100%; */
  }

  @media ${mobileSize} {
    width: 100%;
    min-width: 100%;
    max-height: 400px;
    img {
      width: 100%;
      min-height: 0;
      max-height: 100%;
      /* object-fit: contain */
    }
  }
`;

const LargeHeading = styled(AppTitle as any)`
  font-size: 56px;
  line-height: 70px;
  margin-bottom: 40px;
  @media ${laptopSize} {
    font-size: 40px;
    line-height: 52px;
  }
  @media ${mobileSize} {
    font-size: 36px;
    line-height: 45px;
    max-width: 95%;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
  padding: 32px 40px;
  border-radius: 8px;

  background-color: ${theme.WHITE_COLOR};
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    gap: 12px;
    padding: 16px;
  }
`;

const CenterWrapper = styled.div`
  @media ${mobileSize} {
    text-align: center;
  }
`;
