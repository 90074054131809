import React from "react";
import { BottomNavSegment } from "../../components/PageSegments";
import { BasicLayout } from "../../layouts/BasicLayout";
import {
  HeroSegment,
  OfferSegment,
  StatsSegment,
  TestimoniesSegment,
  ValueSegment,
} from "../../components/PageSegments/offer";
import styled from "styled-components";
import { mobileSize } from "../../util/variables";
import theme from "../../util/theme";
import { useOffer } from "../../hooks/useOffer";
import { TopBanner } from "../../components/ui/TopBanner";
import { StickyNavBar } from "../../components/ui/StickyNavBar";

export default (data: any) => {
  const { showExpiredBanner, priceData } = useOffer(data.location, "A");

  return (
    <BasicLayout>
      <Main>
        {showExpiredBanner && (
          <TopBanner show fixed={false}>
            <BannerText>
              {`The offer you selected has expired, but you can still get ${priceData.percentOff}% off.`}
            </BannerText>
          </TopBanner>
        )}
        <StickyNavBar initiallyWhite />
        <HeroSegment priceData={priceData} />
        <TestimoniesSegment priceData={priceData} />
        <StatsSegment priceData={priceData} />
        <ValueSegment priceData={priceData} />
        <OfferSegment priceData={priceData} />
        <BottomNavSegment />
      </Main>
    </BasicLayout>
  );
};

const Main = styled.main`
  /* padding-top: 140px; */
  background: ${theme.WHITE_COLOR};
  @media ${mobileSize} {
    /* padding-top: 40px; */
  }
`;

const BannerText = styled.p`
  margin: 0;
  line-height: 1.25;
  @media ${mobileSize} {
    font-size: 20px;
  }
`;
