import React, { useState } from "react";
import styled from "styled-components";
import { AppText, AppTitle2, FlexDiv } from "../../ui";
import theme from "../../../util/theme";
import { mobileSize } from "../../../util/variables";
import StarsSrc from "../../../content/assets/stars-gold.svg";
import { AppLink } from "../../ui/AppLink";
import { Awards } from "../index/Awards";
import { OfferSegmentI } from "../../../hooks/useOffer";
import { offer_landing_page_click } from "../../../services/mixpanel/discount-flow-events";
import { CarouselContainer, LeftArrow, RightArrow, SlideTrack, SlideWindow } from "../special-offer/landing/TestimonyCarousel";

export const TestimoniesSegment: React.FC<OfferSegmentI> = ({ priceData }) => {
  const { percentOff, coupon_id, price_id, trial } = priceData;
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide(current => (current === 2 ? 0 : current + 1));
  };

  const prevSlide = () => {
    setCurrentSlide(current => (current === 0 ? 2 : current - 1));
  };

  return (
    <Background>
      <Container>
        <AppTitle2 margin="0" textAlign="center">
          Join millions of people learning on Imprint
        </AppTitle2>
        <Stars src={StarsSrc} alt="5 stars" width={232} height={43} />
        <CarouselContainer>
          <LeftArrow color="#8f9295" aria-label="previous slide" onClick={prevSlide} />
          <SlideWindow>
            <SlideTrack currentSlide={currentSlide}>
              <FlexDiv align="center" justify="center" style={{ width: "100%", flexShrink: 0 }}>
                <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                  “Imprint is <b>completely life changing</b>. As a person that always loves reading
                  but doesn’t have much time, this app is <b>perfect for me</b>.”
                </AppText>
              </FlexDiv>
              <FlexDiv align="center" justify="center" style={{ width: "100%", flexShrink: 0 }}>
                <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                  “This app is <b>one of the best things</b> you can do for yourself.”
                </AppText>
              </FlexDiv>
              <FlexDiv align="center" justify="center" style={{ width: "100%", flexShrink: 0 }}>
                <AppText noMargin fontSize={28} textAlign="center" style={{ maxWidth: 700 }}>
                  “I&apos;m <b>learning</b> during my lunch breaks <b>instead of scrolling</b> through
                  Instagram and I <b>use my new knowledge</b> during my work day.”
                </AppText>
              </FlexDiv>
            </SlideTrack>
          </SlideWindow>
          <RightArrow color="#8f9295" aria-label="next slide" onClick={nextSlide} />
        </CarouselContainer>
        <StyledLink
          to="/subscription/register?from=email"
          state={{
            couponId: coupon_id,
            priceId: price_id,
            trial,
          }}
          onClick={() => offer_landing_page_click("testimony")}
        >
          {percentOff ? `Get ${percentOff}% Off Imprint` : "Start Imprint Free Trial"}
        </StyledLink>
        <Awards dark />
      </Container>
    </Background>
  );
};

const Background = styled.section`
  background-color: ${theme.WHITE_COLOR};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 80px;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    gap: 32px;
    padding: 64px 24px 36px 24px;
  }
`;

const Stars = styled.img`
  @media ${mobileSize} {
    width: 129px;
    height: 24px;
  }
`;

const StyledLink = styled(AppLink as any)`
  margin-bottom: 40px;
  @media ${mobileSize} {
    margin-bottom: 0px;
  }
`;
